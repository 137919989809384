._2LlSYii0vrlqs1siJwuHlp {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 56px 0 60px;
  background: #fafafa;
}
.N7Z3m0UtSzhSxrJpbVrqV {
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  margin-bottom: 16px;
}
._39_d9rMnIPkFb_qeeI7AmS {
  margin-bottom: 36px;
}
.Btt4fj8HbfnqyAcDHys5S {
  color: #ee4d2d;
}
._1mNHoOMBf-4FwJikmJGV2T {
  display: flex;
  align-items: center;
  padding: 17px 15px 20px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.09);
  border-radius: 8px;
  box-sizing: border-box;
  cursor: pointer;
}
._1eu7jPeOPEBOis-8Oxi7q1 {
  flex-shrink: 0;
  width: 60px;
  height: 60px;
  margin-right: 24px;
}
._2gXrkH336bio8zWy_t7Q5s {
  text-align: left;
}
._2gXrkH336bio8zWy_t7Q5s h4 {
  font-weight: bold;
}
@media (max-device-width: 1108px) {
  ._2LlSYii0vrlqs1siJwuHlp {
    padding: 36px 18px 40px;
  }
  .N7Z3m0UtSzhSxrJpbVrqV {
    font-weight: 600;
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 8px;
  }
  ._39_d9rMnIPkFb_qeeI7AmS {
    margin-bottom: 24px;
  }
  ._1mNHoOMBf-4FwJikmJGV2T {
    margin-bottom: 8px;
    cursor: default;
  }
  ._1eu7jPeOPEBOis-8Oxi7q1 {
    width: 36px;
    height: 36px;
    margin-right: 12px;
  }
}
