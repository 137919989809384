._1IsNS7shQy7d4sqPlSRYO {
  width: 100%;
  background: #ffffff;
  border-top: 1px solid rgba(0, 0, 0, 0.09);
}
._2BT7cuASsQBcakyWrrd84x {
  padding: 32px 0 18px;
}
._3FmEW4tC0a3x5cMFPnQmsD {
  display: none;
  margin-bottom: 16px;
}
._3lpUHyDoG_AtH3TKmzC6Qu {
  display: block;
  margin-bottom: 36px;
}
._2lAHEdUx1LKSKAlsInQUer {
  margin: 24px 24px 0;
  font-family: Open Sans;
  font-size: 14px;
  line-height: 22px;
  white-space: pre-wrap;
  text-align: center;
}
._3W6qncDKz735GfordMIbpn {
  display: flex;
  align-items: center;
}
._3W6qncDKz735GfordMIbpn img {
  margin-right: 24px;
}
._2eHn83O-evIuVPXIltoeDk {
  display: flex;
  align-items: self-start;
  margin-top: 30px;
}
._2eHn83O-evIuVPXIltoeDk > a {
  cursor: pointer;
  margin-right: 14px;
  width: 150px;
  height: 47px;
}
.tY_YFjNBB9eHbfZQ3kmWN {
  display: flex;
  align-items: center;
  margin-top: 15px;
}
.tY_YFjNBB9eHbfZQ3kmWN > img {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  cursor: pointer;
}
.tY_YFjNBB9eHbfZQ3kmWN > img:not(:last-child) {
  margin-right: 8px;
}
._3YZJPEBfVve5agqHFqxRLn {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  line-height: 20px;
}
._3YZJPEBfVve5agqHFqxRLn > li {
  width: 200px;
}
._3YZJPEBfVve5agqHFqxRLn > li:not(:nth-last-child(-1n + 2)) {
  margin-bottom: 27px;
}
._3YZJPEBfVve5agqHFqxRLn > li > span {
  display: block;
  margin-bottom: 12px;
}
._3YZJPEBfVve5agqHFqxRLn ._2RLi2BnCXk6JBknwn7_gjB {
  list-style-type: none;
  padding: 0;
}
._3YZJPEBfVve5agqHFqxRLn ._2RLi2BnCXk6JBknwn7_gjB li {
  margin-bottom: 12px;
  cursor: pointer;
}
.w5pFBOTPDaDha4qk1ohoW {
  font-size: 12px;
  line-height: 2;
}
._12mpMn5WX3pycQdp7BIcOI {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  line-height: 20px;
  margin-bottom: 9px;
}
._12mpMn5WX3pycQdp7BIcOI > div {
  width: 200px;
}
._12mpMn5WX3pycQdp7BIcOI > div > span {
  display: block;
  margin-bottom: 16px;
}
._12mpMn5WX3pycQdp7BIcOI ._2RLi2BnCXk6JBknwn7_gjB {
  list-style-type: none;
  padding: 0;
}
._12mpMn5WX3pycQdp7BIcOI ._2RLi2BnCXk6JBknwn7_gjB li {
  margin-bottom: 16px;
  cursor: pointer;
}
._3LaW5NGHc3VCbW8h-LMgWs {
  border-top: 0.5px solid rgba(0, 0, 0, 0.14);
}
._1fqNZLlR0s51Ob12eSEs5z {
  font-family: Open Sans;
  font-size: 14px;
  line-height: 28px;
  color: #797b7c;
  height: 64px;
  padding-top: 12px;
  padding-bottom: 24px;
  text-align: center;
}
@media (max-device-width: 1108px) {
  ._2BT7cuASsQBcakyWrrd84x {
    padding: 50px 24px 30px 24px;
  }
  ._3FmEW4tC0a3x5cMFPnQmsD {
    display: block;
  }
  ._3lpUHyDoG_AtH3TKmzC6Qu {
    display: none;
  }
  ._2eHn83O-evIuVPXIltoeDk {
    flex-wrap: wrap;
    margin-top: 25px;
  }
  ._2eHn83O-evIuVPXIltoeDk > a {
    margin-bottom: 12px;
  }
  ._2eHn83O-evIuVPXIltoeDk > a:last-child {
    margin-bottom: 0;
  }
  ._3YZJPEBfVve5agqHFqxRLn {
    line-height: 36px;
  }
  ._3YZJPEBfVve5agqHFqxRLn > li {
    margin-bottom: 7px;
  }
  ._3YZJPEBfVve5agqHFqxRLn > li:not(:nth-last-child(-1n + 2)) {
    margin-bottom: 7px;
  }
  ._3YZJPEBfVve5agqHFqxRLn > li > span {
    margin-bottom: 0;
  }
  ._3YZJPEBfVve5agqHFqxRLn ._2RLi2BnCXk6JBknwn7_gjB li {
    margin-bottom: 0;
  }
  ._12mpMn5WX3pycQdp7BIcOI > div {
    line-height: 36px;
  }
  ._12mpMn5WX3pycQdp7BIcOI > div > span {
    margin-bottom: 0;
  }
  ._12mpMn5WX3pycQdp7BIcOI ._2RLi2BnCXk6JBknwn7_gjB li {
    margin-bottom: 0px;
  }
  ._1fqNZLlR0s51Ob12eSEs5z {
    line-height: 1;
    height: 75px;
    padding-top: 15.5px;
    padding-bottom: 30px;
  }
  ._3LaW5NGHc3VCbW8h-LMgWs {
    margin: 0 24px;
  }
}
