._3QCPFY9QcduofvvE3QUDb_ {
  display: block;
}
.nuh_IEq-nS0K6TruN6NzF {
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 100px;
}
.-pQs9P5rO0YcxbvgpOenO {
  margin-bottom: 40px;
}
@media (max-device-width: 1108px) {
  ._3QCPFY9QcduofvvE3QUDb_ {
    display: none;
  }
}
