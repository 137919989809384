._2llFz1T6-AN5ay87RgUr7B {
  width: 100%;
  height: 480px;
  display: flex;
  justify-content: center;
  background-color: #ea5f00;
}
.vAsrV8BdpEKrjGBqTVj8g {
  position: relative;
  width: 100%;
}
._23OXY_Yvd6te43xfy10B6Q {
  width: 100%;
  height: 480px;
}
._247vK5UW6HGj3i1U3rWRgZ {
  position: absolute;
  top: -6%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
}
.E6f99I1-DXh5sYNXzFErU {
  width: 504px;
  height: 272px;
  margin-top: 36px;
  margin-bottom: 18px;
}
._1YTB1gnj6ounQP7S__qU-M {
  display: flex;
  justify-content: center;
  width: 504px;
}
._2kzJlFMzeJKMsERCYhjuMY {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 130px;
}
._2kzJlFMzeJKMsERCYhjuMY > a {
  width: 122px;
  height: 37px;
}
._3CiegRok6UUw23D6Coo_oi {
  width: 130px;
  height: 130px;
  margin-left: 16px;
  background-color: #FFF;
  border-radius: 4px;
  overflow: hidden;
}
@media (max-device-width: 1108px) {
  ._2llFz1T6-AN5ay87RgUr7B {
    height: auto;
  }
  ._23OXY_Yvd6te43xfy10B6Q {
    width: 100%;
    height: 320px;
  }
  ._247vK5UW6HGj3i1U3rWRgZ {
    position: static;
    transform: none;
    display: flex;
    justify-content: center;
  }
  .E6f99I1-DXh5sYNXzFErU {
    width: 100%;
    height: auto;
    padding: 36px 24px 0 24px;
    margin: 0;
  }
  ._1YTB1gnj6ounQP7S__qU-M {
    position: static;
    padding-top: 49px;
    padding-bottom: 48px;
    display: flex;
    justify-content: center;
    width: 100%;
  }
  ._2kzJlFMzeJKMsERCYhjuMY {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    width: 248px;
    height: 84px;
  }
  ._2kzJlFMzeJKMsERCYhjuMY > a {
    width: 122px;
    height: 37px;
  }
  ._2kzJlFMzeJKMsERCYhjuMY a:last-child {
    margin-left: 63px;
    margin-top: 10px;
  }
  ._3CiegRok6UUw23D6Coo_oi {
    display: none;
  }
}
