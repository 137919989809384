._237EZnB97XSOqLIIrf-Wsk {
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.09);
  border-radius: 28px;
  width: 100%;
  height: 56px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 0 20px;
}
._3C_1H8Vfjm5LKBRKo6RocF {
  margin-right: 18px;
  fill: rgba(0, 0, 0, 0.2);
}
._1l4PZ4gh3Hgq59sbYKeHjr {
  width: 100%;
  height: 100%;
  font-size: 16px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.87);
}
._1l4PZ4gh3Hgq59sbYKeHjr::placeholder {
  color: rgba(32, 35, 37, 0.6);
}
@media (max-device-width: 1108px) {
  ._237EZnB97XSOqLIIrf-Wsk {
    height: 48px;
    margin-bottom: 60px;
  }
  ._3C_1H8Vfjm5LKBRKo6RocF {
    margin-right: 10px;
  }
}
