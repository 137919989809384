._1Ar-meeDe24Aj-iK1Ao9lT {
  width: 100%;
  height: 64px;
  text-align: center;
  line-height: 64px;
  font-weight: 600;
  background-color: #fafafa;
  border-radius: 2px;
  cursor: pointer;
}
._1d19VECXfedHwoqGv_km67 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 0;
  color: #333a41;
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
}
._1d19VECXfedHwoqGv_km67 img {
  margin-left: 4px;
  width: 14px;
}
@media (max-device-width: 1108px) {
  ._1Ar-meeDe24Aj-iK1Ao9lT {
    height: 48px;
    line-height: 48px;
    cursor: default;
  }
}
