@font-face {
  font-family: 'Open Sans';
  font-weight: 400;
  src: local('OpenSans-Regular'), url('/fonts/OpenSans-Regular.ttf');
}
@font-face {
  font-family: 'Open Sans';
  font-weight: 600;
  src: local('OpenSans-SemiBold'), url('/fonts/OpenSans-SemiBold.ttf');
}
@font-face {
  font-family: 'Shopee 2021';
  font-weight: 900;
  src: local('Shopee2021-ExtraBold'), url('/fonts/Shopee2021-ExtraBold.ttf');
}
html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Open Sans';
  font-size: 14px;
  line-height: 24px;
  color: #202325;
}
@media (min-device-width: 1108px) {
  html,
  body {
    min-width: 1108px;
  }
}
h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
}
a {
  color: inherit;
  text-decoration: none;
}
input {
  background: none;
  outline: none;
  border: 0;
}
ul,
li {
  margin: 0;
  padding: 0;
}
* {
  box-sizing: border-box;
}
.header-wrap {
  position: fixed;
  top: 0;
  height: 64px;
  width: 100%;
  z-index: 10;
}
.content-wrap {
  margin-top: 64px;
  position: relative;
}
.content {
  width: 100%;
  margin: 0 auto;
}
@media (max-device-width: 1108px) {
  .header-wrap {
    height: 48px;
  }
  .content-wrap {
    margin-top: 48px;
  }
}
@media (min-device-width: 1108px) {
  .content {
    width: 980px;
  }
}
@media (min-width: 1108px) {
  .content-wrap {
    position: relative;
  }
  .content {
    width: 980px;
  }
}
@media (min-width: 1420px) {
  .content {
    width: 1260px;
  }
}
.logo {
  width: 111px;
  height: 36px;
}
.center {
  margin: 0 auto;
}
.font-base {
  font-size: 14px;
  line-height: 24px;
}
.font-lg {
  font-size: 16px;
  line-height: 28px;
}
.title-sm {
  font-weight: 600;
  font-size: 22px;
  line-height: 32px;
}
.title-md {
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
}
.title-lg {
  font-family: 'Shopee 2021';
  font-weight: 900;
  font-size: 60px;
  line-height: 72px;
}
.text-black {
  color: #202325;
}
.text-gray {
  color: rgba(32, 35, 37, 0.6);
}
