._11aFIZZjB6AYVAj8X909O1 {
  background-color: #fafafa;
}
._11aFIZZjB6AYVAj8X909O1 ._2EwsNlaCYFLLNHwnY5IllR {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 26px 18px 26px 0;
}
._11aFIZZjB6AYVAj8X909O1 ._2EwsNlaCYFLLNHwnY5IllR .y5MgAvXidxOD9kvG7_oQY {
  cursor: pointer;
}
@media (max-device-width: 1108px) {
  ._11aFIZZjB6AYVAj8X909O1 ._2EwsNlaCYFLLNHwnY5IllR {
    padding: 16px 12px 16px 18px;
  }
}
