._2qIRk0ZlQguGByM3p6-roj {
  padding-bottom: 120px;
  overflow-x: hidden;
}
._1KelamipllKUqSVkDqhkEh {
  text-align: center;
  font-family: Open Sans;
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  color: #000000;
  padding-top: 80px;
  padding-bottom: 24px;
}
._2CCIOlp9Lk8leyl3e7YBiU {
  width: 100%;
  height: 480px;
  background: center / cover no-repeat url('/assets/image/education_banner.png');
}
@media (max-device-width: 1108px) {
  ._2qIRk0ZlQguGByM3p6-roj {
    padding-bottom: 80px;
  }
  ._1KelamipllKUqSVkDqhkEh {
    font-size: 22px;
    line-height: 36px;
    padding-top: 40px;
    padding-bottom: 24px;
  }
  ._2CCIOlp9Lk8leyl3e7YBiU {
    height: 320px;
    background: center / cover no-repeat url('/assets/image/education_banner_mobile.png');
  }
}
