.QPbsLp8nz6Ul4F5xIqt-t {
  position: relative;
  display: flex;
  justify-content: center;
}
._2Oc8rZQcoBK9j0PeBgM-S0 {
  height: 480px;
  width: 100%;
}
._1rrabdxAbq4wgzPmpXvkDh {
  position: absolute;
  top: 178px;
  display: flex;
  justify-content: center;
}
._1DJrhFOGsXLERisrQuI_KF > h1 {
  font-family: 'Shopee 2021';
  font-size: 60px;
  line-height: 90px;
  letter-spacing: 0.02em;
  color: #ffffff;
}
._1DJrhFOGsXLERisrQuI_KF > div {
  font-family: Open Sans;
  font-weight: normal;
  font-size: 22px;
  line-height: 36px;
  color: #ffffff;
  max-width: 60%;
}
._1jgftHovc03Q3iHE4W1WeC {
  display: none;
}
@media (max-device-width: 1108px) {
  .QPbsLp8nz6Ul4F5xIqt-t {
    display: flex;
    flex-direction: column;
  }
  ._2Oc8rZQcoBK9j0PeBgM-S0 {
    height: 320px;
    width: 100%;
  }
  ._1DJrhFOGsXLERisrQuI_KF {
    display: none;
  }
  ._1jgftHovc03Q3iHE4W1WeC {
    display: block;
    height: 174px;
    background-color: #ea5f00;
    padding: 24px 24px 38px 24px;
    z-index: 2;
  }
  ._1jgftHovc03Q3iHE4W1WeC > h1 {
    font-family: 'Shopee 2021';
    font-size: 32px;
    line-height: 48px;
    letter-spacing: 0;
    margin-bottom: 8px;
    color: #ffffff;
  }
  ._1jgftHovc03Q3iHE4W1WeC > div {
    font-family: Open Sans;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    color: #ffffff;
  }
}
