.rlCa_2pbqhafeHsD2DWSV {
  width: 100%;
  height: 720px;
  position: relative;
  overflow: hidden;
}
._3WE6drO7wdRltAkkf6Gldi {
  max-width: 40%;
  color: #ffffff;
}
._3WE6drO7wdRltAkkf6Gldi h1 {
  font-family: 'Shopee 2021';
  font-weight: 900;
  line-height: 72px;
  font-size: 60px;
  line-height: 70px;
  letter-spacing: 0.02em;
  margin-bottom: 26px;
}
._3WE6drO7wdRltAkkf6Gldi p {
  font-size: 22px;
  line-height: 36px;
}
.czVkSoJUFXrl8MD3P_Jul {
  margin-top: 36px;
  display: flex;
  justify-content: space-between;
  width: 332px;
}
.czVkSoJUFXrl8MD3P_Jul ._230mOQO_-gh-Bay5mKP45O {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 162px;
}
.czVkSoJUFXrl8MD3P_Jul ._230mOQO_-gh-Bay5mKP45O > a {
  width: 150px;
  height: 46px;
}
.czVkSoJUFXrl8MD3P_Jul ._3q8xhvf3X_WVokwOjS9dKP {
  width: 162px;
  height: 162px;
  background-color: #FFF;
  border-radius: 4px;
  overflow: hidden;
}
.A9N-3U3V2-78Cl4pnZ2oM {
  display: block;
  position: absolute;
  top: 127px;
}
._3Q5_wbv2JG7WMNfBf57gQ6 {
  display: none;
}
@media (max-device-width: 1108px) {
  .rlCa_2pbqhafeHsD2DWSV {
    height: 850px;
    background-color: #ea5f00;
  }
  ._3WE6drO7wdRltAkkf6Gldi {
    width: 100%;
    max-width: 100%;
    padding: 0 24px;
    color: #ffffff;
  }
  ._3WE6drO7wdRltAkkf6Gldi h1 {
    font-size: 32px;
    line-height: 48px;
    letter-spacing: 0;
    margin-bottom: 8px;
  }
  ._3WE6drO7wdRltAkkf6Gldi p {
    font-size: 16px;
    line-height: 28px;
  }
  .czVkSoJUFXrl8MD3P_Jul {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .czVkSoJUFXrl8MD3P_Jul ._230mOQO_-gh-Bay5mKP45O {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    width: 311px;
    height: 104px;
  }
  .czVkSoJUFXrl8MD3P_Jul ._230mOQO_-gh-Bay5mKP45O > a {
    width: 150px;
    height: 46px;
  }
  .czVkSoJUFXrl8MD3P_Jul ._230mOQO_-gh-Bay5mKP45O a:last-child {
    margin-left: 78px;
    margin-top: 12px;
  }
  .czVkSoJUFXrl8MD3P_Jul ._3q8xhvf3X_WVokwOjS9dKP {
    display: none;
  }
  .A9N-3U3V2-78Cl4pnZ2oM {
    display: none;
  }
  ._3Q5_wbv2JG7WMNfBf57gQ6 {
    display: block;
    position: absolute;
    left: 0;
    bottom: 52px;
  }
}
