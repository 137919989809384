._3ToJB_OxYbgxBg8Iprlwro {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 20;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.IzEH36mpDDuoXJC9z3J02 {
  padding: 20px;
  border-radius: 8px;
  background-color: #ffffff;
}
._3hFkLI4axWBiqXattw4fC0 {
  display: flex;
  justify-content: space-between;
}
._2h8ERVlKTsrkCqM3i9Zs5a {
  width: 10px;
  height: 10px;
}
._1qNDWxlPtAf2POZCUzhTW1 {
  height: 42px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}
._6Gr0_-KxwmacuK6GWHEVm {
  border: 1px solid #ea5f00;
  flex: 1;
}
._2B2ao9FSi9wySIKv5Pd2pA {
  background-color: #ea5f00;
  flex: 1;
}
._2M1SRvLbdW_bRI6msq0iWZ {
  line-height: 16px;
}
._2DGhgxalugXiMPQucVOhsc {
  color: #ea5f00;
}
._3l1JVXZSZ1KZHGAApwXkch {
  color: #ffffff;
}
