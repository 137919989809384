._2qzq4iOqq009KsgKSM5NGo {
  display: flex;
  width: 100%;
}
._2qzq4iOqq009KsgKSM5NGo ._12XtO30LCWmUFNQb45yhpv {
  text-align: center;
  margin: 80px auto 0;
}
._2qzq4iOqq009KsgKSM5NGo ._12XtO30LCWmUFNQb45yhpv h1 {
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  letter-spacing: 0.02em;
  margin-bottom: 48px;
}
._2qzq4iOqq009KsgKSM5NGo ._12XtO30LCWmUFNQb45yhpv ._1RHcJ4fRAjxK-xCTPWz-LZ {
  font-weight: 600;
  font-size: 22px;
  line-height: 32px;
  color: #333a41;
  margin-bottom: 24px;
}
._1lkNxZK2gbbZyziCi9ai9i {
  width: 100%;
  height: 480px;
  background: center / cover no-repeat;
  color: #ffffff;
  background-color: #ea5f00;
}
._1lkNxZK2gbbZyziCi9ai9i ._1nKc5L8hGcaHY9FDWsPcd0 {
  display: none;
  width: 100%;
}
._1lkNxZK2gbbZyziCi9ai9i ._1nKc5L8hGcaHY9FDWsPcd0 img {
  width: 100%;
}
._1lkNxZK2gbbZyziCi9ai9i ._2xh_tBgbwHs3dilcjJed3Z {
  margin-top: 177px;
  margin-left: 108px;
}
._1lkNxZK2gbbZyziCi9ai9i ._2xh_tBgbwHs3dilcjJed3Z h1 {
  font-family: 'Shopee 2021';
  font-size: 60px;
  line-height: 90px;
}
._1lkNxZK2gbbZyziCi9ai9i ._2xh_tBgbwHs3dilcjJed3Z p {
  font-size: 22px;
  line-height: 36px;
}
@media (max-device-width: 1108px) {
  ._2qzq4iOqq009KsgKSM5NGo ._12XtO30LCWmUFNQb45yhpv {
    margin: 36px auto 0;
  }
  ._2qzq4iOqq009KsgKSM5NGo ._12XtO30LCWmUFNQb45yhpv h1 {
    font-weight: 600;
    font-size: 22px;
    line-height: 32px;
    margin: 0 18px 36px;
  }
  ._2qzq4iOqq009KsgKSM5NGo ._12XtO30LCWmUFNQb45yhpv ._1RHcJ4fRAjxK-xCTPWz-LZ {
    font-size: 16px;
    line-height: 28px;
    margin: 0 18px 24px;
  }
  ._2qzq4iOqq009KsgKSM5NGo ._1iseUjGAINfEJDHN3UK_R {
    margin: 16px auto 0;
  }
  ._1lkNxZK2gbbZyziCi9ai9i {
    background-image: none;
    height: auto;
  }
  ._1lkNxZK2gbbZyziCi9ai9i ._1nKc5L8hGcaHY9FDWsPcd0 {
    display: block;
  }
  ._1lkNxZK2gbbZyziCi9ai9i ._2xh_tBgbwHs3dilcjJed3Z {
    margin: 24px 24px 20px;
  }
  ._1lkNxZK2gbbZyziCi9ai9i ._2xh_tBgbwHs3dilcjJed3Z h1 {
    font-size: 36px;
    line-height: 48px;
    margin-bottom: 8px;
  }
  ._1lkNxZK2gbbZyziCi9ai9i ._2xh_tBgbwHs3dilcjJed3Z p {
    font-size: 16px;
    line-height: 28px;
  }
}
