._1h2qCfyNC2zxlvTlDnRqia {
  margin-bottom: 40px;
  background-color: #ffffff;
}
._1h2qCfyNC2zxlvTlDnRqia ._2YIOcKeqmzAIUcTt_NYxSA {
  display: flex;
  align-items: center;
  padding-left: 10px;
  height: 42px;
  font-size: 18px;
  font-weight: 600;
  color: #ea5f00;
  border: 1px solid #EEEFF2;
}
._1h2qCfyNC2zxlvTlDnRqia ._2YIOcKeqmzAIUcTt_NYxSA img {
  width: 22px;
}
._1h2qCfyNC2zxlvTlDnRqia ._3YfozXzVg1U5Dao-3DpNgL {
  padding-top: 16px;
  padding-bottom: 24px;
  font-weight: 600;
  text-align: center;
  font-size: 22px;
  line-height: 32px;
}
._1h2qCfyNC2zxlvTlDnRqia ._19klPZYfDdRwB3NElqIllW {
  margin: 0 16px;
}
._1h2qCfyNC2zxlvTlDnRqia ._1r-kG3ttRRWUPP54Cq08ou {
  font-size: 16px;
  line-height: 20px;
}
._1h2qCfyNC2zxlvTlDnRqia ._1r-kG3ttRRWUPP54Cq08ou ._2gkvk9VB8lGWSwOIrWr93E {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.87);
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
}
._1h2qCfyNC2zxlvTlDnRqia ._36PqPMrHAhwqCYQC9W_fQy {
  width: 12px;
  height: 12px;
}
.-N1aUCP84S4CDjLPK24hj {
  display: inline-block;
  padding: 20px 0;
  width: 100%;
  color: #333a41;
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
}
.hawn9aAlQ9EMt6aPB0Mi9 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.hawn9aAlQ9EMt6aPB0Mi9 img {
  margin-left: 4px;
  width: 14px;
}
