._1xZXkXI1n_KRrFzYP6EBMh {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.54);
}
._3OoQLTg0CRo7l9TlW5G9qs {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 120px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  background: #ffffff;
}
._3Gue3Xtulve4Eec_J_JA8V {
  width: 42px;
  height: 42px;
}
@media (max-device-width: 1108px) {
  ._1xZXkXI1n_KRrFzYP6EBMh {
    background: inherit;
  }
  ._3OoQLTg0CRo7l9TlW5G9qs {
    width: 64px;
    height: 64px;
  }
  ._3Gue3Xtulve4Eec_J_JA8V {
    width: 32px;
    height: 32px;
  }
}
._1162ibGROyT_C63oXQu2B9 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
._1z-hxjJKEkk4solKito8rq {
  width: 32px;
  height: 32px;
}
._2BU9EL7ZeUPU6QsW-LwTUD {
  color: rgba(0, 0, 0, 0.87);
  line-height: 16px;
  margin-top: 16px;
}
