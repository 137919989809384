._3Ylit_U0NAw0LozdRTDLUR {
  margin-bottom: 200px;
}
._3Ylit_U0NAw0LozdRTDLUR img {
  max-width: 100%;
}
._3Ylit_U0NAw0LozdRTDLUR h1,
._3Ylit_U0NAw0LozdRTDLUR h2,
._3Ylit_U0NAw0LozdRTDLUR h3,
._3Ylit_U0NAw0LozdRTDLUR h4 {
  color: #111;
  font-weight: 400;
  margin-top: 1em;
}
._3Ylit_U0NAw0LozdRTDLUR dl,
._3Ylit_U0NAw0LozdRTDLUR h1,
._3Ylit_U0NAw0LozdRTDLUR h2,
._3Ylit_U0NAw0LozdRTDLUR h3,
._3Ylit_U0NAw0LozdRTDLUR h4,
._3Ylit_U0NAw0LozdRTDLUR h5 {
  margin-bottom: 16px;
  padding: 0;
}
._3Ylit_U0NAw0LozdRTDLUR p {
  margin-top: 8px;
  margin-bottom: 3px;
}
._3Ylit_U0NAw0LozdRTDLUR h1 {
  font-size: 24px;
  line-height: 28px;
}
._3Ylit_U0NAw0LozdRTDLUR h2 {
  font-size: 22px;
  line-height: 26px;
}
._3Ylit_U0NAw0LozdRTDLUR h1,
._3Ylit_U0NAw0LozdRTDLUR h2 {
  border-bottom: 1px solid #efeaea;
  padding-bottom: 10px;
}
._3Ylit_U0NAw0LozdRTDLUR h3 {
  font-size: 20px;
  line-height: 24px;
}
._3Ylit_U0NAw0LozdRTDLUR h4 {
  font-size: 18px;
  line-height: 22px;
}
._3Ylit_U0NAw0LozdRTDLUR h5 {
  font-size: 16px;
  line-height: 18px;
}
._3Ylit_U0NAw0LozdRTDLUR p {
  margin-bottom: 10px;
  color: rgba(32, 35, 37, 0.6);
}
._3Ylit_U0NAw0LozdRTDLUR a {
  color: #09f;
  margin: 0 2px;
  padding: 0;
  vertical-align: baseline;
  text-decoration: none;
}
._3Ylit_U0NAw0LozdRTDLUR a:hover {
  text-decoration: none;
  color: #f60;
}
._3Ylit_U0NAw0LozdRTDLUR ol,
._3Ylit_U0NAw0LozdRTDLUR ul {
  padding: 0;
  padding-left: 18px;
  margin: 0;
}
._3Ylit_U0NAw0LozdRTDLUR li {
  line-height: 28px;
}
._3Ylit_U0NAw0LozdRTDLUR ol,
._3Ylit_U0NAw0LozdRTDLUR p,
._3Ylit_U0NAw0LozdRTDLUR ul {
  font-size: 16px;
  line-height: 28px;
}
._3Ylit_U0NAw0LozdRTDLUR ol ol,
._3Ylit_U0NAw0LozdRTDLUR ul ol {
  list-style-type: lower-roman;
}
._3Ylit_U0NAw0LozdRTDLUR code,
._3Ylit_U0NAw0LozdRTDLUR pre {
  background-color: #f7f7f7;
  color: inherit;
}
._3Ylit_U0NAw0LozdRTDLUR code {
  margin: 0 2px;
}
._3Ylit_U0NAw0LozdRTDLUR pre {
  line-height: 1.7em;
  overflow: auto;
  padding: 6px 10px;
}
._3Ylit_U0NAw0LozdRTDLUR pre > code {
  border: 0;
  display: inline;
  max-width: initial;
  padding: 0;
  margin: 0;
  overflow: initial;
  line-height: 1.6em;
  font-size: 0.95em;
  white-space: pre;
  background: 0;
}
._3Ylit_U0NAw0LozdRTDLUR code {
  color: #666555;
}
._3Ylit_U0NAw0LozdRTDLUR aside {
  display: block;
  float: right;
  width: 390px;
}
._3Ylit_U0NAw0LozdRTDLUR blockquote {
  border-left: 0.5em solid #eee;
  padding: 0 0 0 2em;
  margin-left: 0;
}
._3Ylit_U0NAw0LozdRTDLUR blockquote cite {
  font-size: 14px;
  line-height: 20px;
  color: #bfbfbf;
}
._3Ylit_U0NAw0LozdRTDLUR blockquote cite:before {
  content: '\2014   \A0';
}
._3Ylit_U0NAw0LozdRTDLUR blockquote p {
  color: #666;
}
._3Ylit_U0NAw0LozdRTDLUR hr {
  text-align: left;
  color: #999;
  height: 2px;
  padding: 0;
  margin: 16px 0;
  background-color: #e7e7e7;
  border: 0 none;
}
._3Ylit_U0NAw0LozdRTDLUR dl {
  padding: 0;
}
._3Ylit_U0NAw0LozdRTDLUR dl dt {
  padding: 10px 0;
  margin-top: 16px;
  font-size: 1em;
  font-style: italic;
  font-weight: 700;
}
._3Ylit_U0NAw0LozdRTDLUR dl dd {
  padding: 0 16px;
  margin-bottom: 16px;
}
._3Ylit_U0NAw0LozdRTDLUR dd {
  margin-left: 0;
}
._3Ylit_U0NAw0LozdRTDLUR table {
  border-spacing: 0;
  max-width: 100%;
  margin-bottom: 10px;
}
._3Ylit_U0NAw0LozdRTDLUR table {
  width: 100%;
  border: solid #eeeff2 1px;
  margin: 16px 0;
  overflow: auto;
}
._3Ylit_U0NAw0LozdRTDLUR table thead {
  background: #ea5f00;
}
._3Ylit_U0NAw0LozdRTDLUR table thead tr th {
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
}
._3Ylit_U0NAw0LozdRTDLUR ._2o8st-TNl1x5qK7btDh-up th,
._3Ylit_U0NAw0LozdRTDLUR table td {
  font-size: 16px;
  line-height: 28px;
  border-left: 1px solid #eeeff2;
  border-top: 1px solid #eeeff2;
  padding: 10px 15px;
  text-align: center;
}
._3Ylit_U0NAw0LozdRTDLUR table th {
  border-top: 0;
  font-size: 16px;
  line-height: 28px;
  padding: 10px 15px;
  border-left: 1px solid #eeeff2;
  text-align: center;
}
._3Ylit_U0NAw0LozdRTDLUR table td:first-child,
._3Ylit_U0NAw0LozdRTDLUR table th:first-child {
  border-left: none;
}
._3Ylit_U0NAw0LozdRTDLUR.no-header-underline h1,
._3Ylit_U0NAw0LozdRTDLUR .no-header-underline h1,
._3Ylit_U0NAw0LozdRTDLUR.no-header-underline h2,
._3Ylit_U0NAw0LozdRTDLUR .no-header-underline h2 {
  border-bottom: none;
  margin-bottom: 1em;
  padding-bottom: 0;
}
._3Ylit_U0NAw0LozdRTDLUR .table-wrap {
  overflow: auto;
}
@media (max-device-width: 1108px) {
  ._3Ylit_U0NAw0LozdRTDLUR {
    padding: 0 18px;
  }
}
