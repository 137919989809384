.row {
  display: flex;
  flex-flow: row wrap;
}
.row::before,
.row::after {
  display: flex;
}
.row-start {
  justify-content: flex-start;
}
.row-center {
  justify-content: center;
}
.row-end {
  justify-content: flex-end;
}
.row-space-between {
  justify-content: space-between;
}
.row-space-around {
  justify-content: space-around;
}
.row-top {
  align-items: flex-start;
}
.row-middle {
  align-items: center;
}
.row-bottom {
  align-items: flex-end;
}
.col {
  position: relative;
  max-width: 100%;
  min-height: 1px;
}
.col-12 {
  display: block;
  flex: 0 0 100%;
  max-width: 100%;
}
.col-offset-12 {
  margin-left: 100%;
}
.col-reverse-offset-12 {
  margin-right: 100%;
}
.col-order-12 {
  order: 12;
}
.col-11 {
  display: block;
  flex: 0 0 91.42857143%;
  max-width: 91.42857143%;
}
.col-offset-11 {
  margin-left: 91.66666667%;
}
.col-reverse-offset-11 {
  margin-right: 91.66666667%;
}
.col-order-11 {
  order: 11;
}
.col-10 {
  display: block;
  flex: 0 0 82.85714286%;
  max-width: 82.85714286%;
}
.col-offset-10 {
  margin-left: 83.33333333%;
}
.col-reverse-offset-10 {
  margin-right: 83.33333333%;
}
.col-order-10 {
  order: 10;
}
.col-9 {
  display: block;
  flex: 0 0 74.28571429%;
  max-width: 74.28571429%;
}
.col-offset-9 {
  margin-left: 75%;
}
.col-reverse-offset-9 {
  margin-right: 75%;
}
.col-order-9 {
  order: 9;
}
.col-8 {
  display: block;
  flex: 0 0 65.71428571%;
  max-width: 65.71428571%;
}
.col-offset-8 {
  margin-left: 66.66666667%;
}
.col-reverse-offset-8 {
  margin-right: 66.66666667%;
}
.col-order-8 {
  order: 8;
}
.col-7 {
  display: block;
  flex: 0 0 57.14285714%;
  max-width: 57.14285714%;
}
.col-offset-7 {
  margin-left: 58.33333333%;
}
.col-reverse-offset-7 {
  margin-right: 58.33333333%;
}
.col-order-7 {
  order: 7;
}
.col-6 {
  display: block;
  flex: 0 0 48.57142857%;
  max-width: 48.57142857%;
}
.col-offset-6 {
  margin-left: 50%;
}
.col-reverse-offset-6 {
  margin-right: 50%;
}
.col-order-6 {
  order: 6;
}
.col-5 {
  display: block;
  flex: 0 0 40%;
  max-width: 40%;
}
.col-offset-5 {
  margin-left: 41.66666667%;
}
.col-reverse-offset-5 {
  margin-right: 41.66666667%;
}
.col-order-5 {
  order: 5;
}
.col-4 {
  display: block;
  flex: 0 0 31.42857143%;
  max-width: 31.42857143%;
}
.col-offset-4 {
  margin-left: 33.33333333%;
}
.col-reverse-offset-4 {
  margin-right: 33.33333333%;
}
.col-order-4 {
  order: 4;
}
.col-3 {
  display: block;
  flex: 0 0 22.85714286%;
  max-width: 22.85714286%;
}
.col-offset-3 {
  margin-left: 25%;
}
.col-reverse-offset-3 {
  margin-right: 25%;
}
.col-order-3 {
  order: 3;
}
.col-2 {
  display: block;
  flex: 0 0 14.28571429%;
  max-width: 14.28571429%;
}
.col-offset-2 {
  margin-left: 16.66666667%;
}
.col-reverse-offset-2 {
  margin-right: 16.66666667%;
}
.col-order-2 {
  order: 2;
}
.col-1 {
  display: block;
  flex: 0 0 5.71428571%;
  max-width: 5.71428571%;
}
.col-offset-1 {
  margin-left: 8.33333333%;
}
.col-reverse-offset-1 {
  margin-right: 8.33333333%;
}
.col-order-1 {
  order: 1;
}
.col-0 {
  display: none;
}
.col-offset-0 {
  margin-left: 0;
}
.col-order-0 {
  order: 0;
}
@media (min-device-width: 1108px) {
  .col-md-12 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .col-md-offset-12 {
    margin-left: 100%;
  }
  .col-md-reverse-offset-12 {
    margin-right: 100%;
  }
  .col-md-order-12 {
    order: 12;
  }
  .col-md-11 {
    display: block;
    flex: 0 0 91.42857143%;
    max-width: 91.42857143%;
  }
  .col-md-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-md-reverse-offset-11 {
    margin-right: 91.66666667%;
  }
  .col-md-order-11 {
    order: 11;
  }
  .col-md-10 {
    display: block;
    flex: 0 0 82.85714286%;
    max-width: 82.85714286%;
  }
  .col-md-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-md-reverse-offset-10 {
    margin-right: 83.33333333%;
  }
  .col-md-order-10 {
    order: 10;
  }
  .col-md-9 {
    display: block;
    flex: 0 0 74.28571429%;
    max-width: 74.28571429%;
  }
  .col-md-offset-9 {
    margin-left: 75%;
  }
  .col-md-reverse-offset-9 {
    margin-right: 75%;
  }
  .col-md-order-9 {
    order: 9;
  }
  .col-md-8 {
    display: block;
    flex: 0 0 65.71428571%;
    max-width: 65.71428571%;
  }
  .col-md-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-md-reverse-offset-8 {
    margin-right: 66.66666667%;
  }
  .col-md-order-8 {
    order: 8;
  }
  .col-md-7 {
    display: block;
    flex: 0 0 57.14285714%;
    max-width: 57.14285714%;
  }
  .col-md-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-md-reverse-offset-7 {
    margin-right: 58.33333333%;
  }
  .col-md-order-7 {
    order: 7;
  }
  .col-md-6 {
    display: block;
    flex: 0 0 48.57142857%;
    max-width: 48.57142857%;
  }
  .col-md-offset-6 {
    margin-left: 50%;
  }
  .col-md-reverse-offset-6 {
    margin-right: 50%;
  }
  .col-md-order-6 {
    order: 6;
  }
  .col-md-5 {
    display: block;
    flex: 0 0 40%;
    max-width: 40%;
  }
  .col-md-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-md-reverse-offset-5 {
    margin-right: 41.66666667%;
  }
  .col-md-order-5 {
    order: 5;
  }
  .col-md-4 {
    display: block;
    flex: 0 0 31.42857143%;
    max-width: 31.42857143%;
  }
  .col-md-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-md-reverse-offset-4 {
    margin-right: 33.33333333%;
  }
  .col-md-order-4 {
    order: 4;
  }
  .col-md-3 {
    display: block;
    flex: 0 0 22.85714286%;
    max-width: 22.85714286%;
  }
  .col-md-offset-3 {
    margin-left: 25%;
  }
  .col-md-reverse-offset-3 {
    margin-right: 25%;
  }
  .col-md-order-3 {
    order: 3;
  }
  .col-md-2 {
    display: block;
    flex: 0 0 14.28571429%;
    max-width: 14.28571429%;
  }
  .col-md-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-md-reverse-offset-2 {
    margin-right: 16.66666667%;
  }
  .col-md-order-2 {
    order: 2;
  }
  .col-md-1 {
    display: block;
    flex: 0 0 5.71428571%;
    max-width: 5.71428571%;
  }
  .col-md-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-md-reverse-offset-1 {
    margin-right: 8.33333333%;
  }
  .col-md-order-1 {
    order: 1;
  }
  .col-md-0 {
    display: none;
  }
  .col-md-offset-0 {
    margin-left: 0;
  }
  .col-md-order-0 {
    order: 0;
  }
}
@media (min-device-width: 1420px) {
  .col-lg-12 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .col-lg-offset-12 {
    margin-left: 100%;
  }
  .col-lg-reverse-offset-12 {
    margin-right: 100%;
  }
  .col-lg-order-12 {
    order: 12;
  }
  .col-lg-11 {
    display: block;
    flex: 0 0 91.42857143%;
    max-width: 91.42857143%;
  }
  .col-lg-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-lg-reverse-offset-11 {
    margin-right: 91.66666667%;
  }
  .col-lg-order-11 {
    order: 11;
  }
  .col-lg-10 {
    display: block;
    flex: 0 0 82.85714286%;
    max-width: 82.85714286%;
  }
  .col-lg-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-lg-reverse-offset-10 {
    margin-right: 83.33333333%;
  }
  .col-lg-order-10 {
    order: 10;
  }
  .col-lg-9 {
    display: block;
    flex: 0 0 74.28571429%;
    max-width: 74.28571429%;
  }
  .col-lg-offset-9 {
    margin-left: 75%;
  }
  .col-lg-reverse-offset-9 {
    margin-right: 75%;
  }
  .col-lg-order-9 {
    order: 9;
  }
  .col-lg-8 {
    display: block;
    flex: 0 0 65.71428571%;
    max-width: 65.71428571%;
  }
  .col-lg-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-lg-reverse-offset-8 {
    margin-right: 66.66666667%;
  }
  .col-lg-order-8 {
    order: 8;
  }
  .col-lg-7 {
    display: block;
    flex: 0 0 57.14285714%;
    max-width: 57.14285714%;
  }
  .col-lg-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-lg-reverse-offset-7 {
    margin-right: 58.33333333%;
  }
  .col-lg-order-7 {
    order: 7;
  }
  .col-lg-6 {
    display: block;
    flex: 0 0 48.57142857%;
    max-width: 48.57142857%;
  }
  .col-lg-offset-6 {
    margin-left: 50%;
  }
  .col-lg-reverse-offset-6 {
    margin-right: 50%;
  }
  .col-lg-order-6 {
    order: 6;
  }
  .col-lg-5 {
    display: block;
    flex: 0 0 40%;
    max-width: 40%;
  }
  .col-lg-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-lg-reverse-offset-5 {
    margin-right: 41.66666667%;
  }
  .col-lg-order-5 {
    order: 5;
  }
  .col-lg-4 {
    display: block;
    flex: 0 0 31.42857143%;
    max-width: 31.42857143%;
  }
  .col-lg-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-lg-reverse-offset-4 {
    margin-right: 33.33333333%;
  }
  .col-lg-order-4 {
    order: 4;
  }
  .col-lg-3 {
    display: block;
    flex: 0 0 22.85714286%;
    max-width: 22.85714286%;
  }
  .col-lg-offset-3 {
    margin-left: 25%;
  }
  .col-lg-reverse-offset-3 {
    margin-right: 25%;
  }
  .col-lg-order-3 {
    order: 3;
  }
  .col-lg-2 {
    display: block;
    flex: 0 0 14.28571429%;
    max-width: 14.28571429%;
  }
  .col-lg-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-lg-reverse-offset-2 {
    margin-right: 16.66666667%;
  }
  .col-lg-order-2 {
    order: 2;
  }
  .col-lg-1 {
    display: block;
    flex: 0 0 5.71428571%;
    max-width: 5.71428571%;
  }
  .col-lg-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-lg-reverse-offset-1 {
    margin-right: 8.33333333%;
  }
  .col-lg-order-1 {
    order: 1;
  }
  .col-lg-0 {
    display: none;
  }
  .col-lg-offset-0 {
    margin-left: 0;
  }
  .col-lg-order-0 {
    order: 0;
  }
}
