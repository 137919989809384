._2XoigjTrRBltoORG3deboZ {
  margin: 8px 0 20px;
  width: 360px;
}
._2BRi7nRDOtf7B443uDdKDw {
  width: 276px;
}
._2i28LlWjAzjl6rSkCI994W {
  font-weight: 500;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.87);
  text-align: center;
  margin-bottom: 24px;
  line-height: 22px;
}
._2jk41lQl5NlLMksZQm40o3 {
  margin-bottom: 16px;
}
._3Zr8Zvy7eHtJnEqsvYcc-a {
  box-sizing: border-box;
  border: 0.5px solid rgba(0, 0, 0, 0.09);
  border-radius: 4px;
}
._22f5xYhv2Tku9IBemVI6Cn {
  box-sizing: border-box;
  height: 40px;
  background-color: #fafafa;
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: inset 0px -0.5px 0px rgba(0, 0, 0, 0.09);
}
._3awUC5khUZIzKHhZ-pzq7T {
  color: rgba(0, 0, 0, 0.87);
  line-height: 16px;
}
._15T25PCuyGGQMXQiGTQhmd {
  box-sizing: border-box;
  box-shadow: inset 0px -0.5px 0px rgba(0, 0, 0, 0.09);
  padding: 12px 0;
  margin: 0 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
._2Gj6XkzDPMzBxcxMDyjE9i {
  box-shadow: none;
}
._2UnI0SAds4kvm3Ra_43NtR {
  white-space: pre-wrap;
  color: rgba(0, 0, 0, 0.87);
  font-size: 13px;
  line-height: 16px;
}
.sQuwy8XyCx5wpkTP-h2q7 {
  width: 210px;
}
._17pxMFnHizKBftZ1tC0Oby {
  width: 132px;
}
._1-GTNTOQfpNXnTbAX0u1pO {
  white-space: pre-wrap;
  font-size: 12px;
  color: rgba(32, 35, 37, 0.6);
  margin-top: 16px;
  line-height: 14px;
}
