._2Z8N-nTKlR6YLXsj_YHN0b {
  width: 100%;
  position: relative;
  overflow: hidden;
}
._1BfcZGdyrscuUfShlYK5-A {
  position: relative;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
}
._1f--UB7N4S5o34YiVncWme {
  width: 100%;
  user-select: none;
}
._1johpUztn00EV2n8CHXLbL {
  position: absolute;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}
._1johpUztn00EV2n8CHXLbL > div {
  display: inline-block;
  overflow: hidden;
  cursor: pointer;
  background: #ffffff;
}
._1johpUztn00EV2n8CHXLbL > div:last-child {
  margin-right: 0;
}
