._2U52J2je3pK85TbV10D7rY {
  margin-bottom: 40px;
}
._2U52J2je3pK85TbV10D7rY .RaL2XoBDRqgavmmD61OYQ {
  width: auto;
}
.AAlfnmdVIjnRO2x_NDNw1 {
  margin: 100px 0;
  text-align: center;
}
.AAlfnmdVIjnRO2x_NDNw1 p {
  font-size: 16px;
  line-height: 24px;
  color: rgba(32, 35, 37, 0.6);
}
.AAlfnmdVIjnRO2x_NDNw1 .oxUsX6HmEUHxMVv7vnNLg {
  font-size: 36px;
  line-height: 44px;
  color: #333a41;
  font-weight: bold;
  margin-bottom: 50px;
}
._3D71vU8NqzoDxCGkf2pDyW {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 200px);
  color: rgba(0, 0, 0, 0.54);
}
._3D71vU8NqzoDxCGkf2pDyW img {
  width: 160px;
  height: 160px;
}
@media (min-device-width: 1108px) {
  ._39ABsFzEFJXJUax8yQ5hUQ {
    margin-top: 80px;
  }
  .RaL2XoBDRqgavmmD61OYQ {
    padding-left: 60px;
  }
  .RaL2XoBDRqgavmmD61OYQ h2 {
    margin-top: 80px;
    margin-bottom: 80px;
    text-align: left;
  }
  .RaL2XoBDRqgavmmD61OYQ > div:first-of-type {
    max-width: 100%;
    margin-left: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.09);
  }
  .RaL2XoBDRqgavmmD61OYQ .pJdFqNggVTBWcxUFgVV6P > div:first-child {
    margin: 24px 0;
  }
}
@media (max-device-width: 1108px) {
  .RaL2XoBDRqgavmmD61OYQ {
    margin-bottom: 0;
  }
  .RaL2XoBDRqgavmmD61OYQ h2 {
    font-weight: 600;
    font-size: 22px;
    line-height: 32px;
    margin: 36px auto 24px auto;
    max-width: 288px;
  }
  .RaL2XoBDRqgavmmD61OYQ > div:last-child {
    margin: 0 !important;
  }
  .RaL2XoBDRqgavmmD61OYQ .pJdFqNggVTBWcxUFgVV6P > div:first-child {
    margin: 20px 0;
  }
  ._1udZ4ivfNwDMLoFs2L31zw {
    padding: 12px 18px 60px;
    overflow-y: scroll;
  }
  ._1udZ4ivfNwDMLoFs2L31zw ._2Oltntqse9S-u7KwK16mKx {
    padding: 12px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  ._1udZ4ivfNwDMLoFs2L31zw ._2Oltntqse9S-u7KwK16mKx p {
    font-size: 16px;
    line-height: 28px;
    color: #333a41;
  }
  ._1udZ4ivfNwDMLoFs2L31zw ._2Oltntqse9S-u7KwK16mKx em {
    font-style: normal;
    color: #ea5f00;
  }
  ._1udZ4ivfNwDMLoFs2L31zw ._1Bnu0Gk1ixhKQgoBaMuRMc {
    padding-top: 50px;
    padding-bottom: 28px;
    text-align: center;
    font-size: 22px;
    font-weight: 600;
    color: #333a41;
  }
  ._1udZ4ivfNwDMLoFs2L31zw ._1Bnu0Gk1ixhKQgoBaMuRMc ._3g_25F_f9TBhmDxkbSotKO {
    color: #ea5f00;
  }
}
