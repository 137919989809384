._3RazTYfrCNNK7gEIkvfqLh {
  margin-bottom: 40px;
}
._3Jrpm_f6pPcGhdTiceat-j {
  background-color: #F6F7F8;
  border-radius: 8px;
  width: 100%;
  height: 62px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 16px 12px;
}
._1YltWSFPFNJ2ya0yX3mei8 path[fill-rule="evenodd"] {
  fill: rgba(0, 0, 0, 0.25);
}
._1j1wdPBOY7T1BI3btsG9TN {
  display: flex;
  flex: 1;
  align-items: baseline;
}
._1j1wdPBOY7T1BI3btsG9TN ._2MjR5l0yqnj4pjTe2AcN4N {
  font-family: Open Sans;
  font-size: 14px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.4);
  margin-right: 2px;
}
._1j1wdPBOY7T1BI3btsG9TN ._36Xc6cE4IRqetWyJClSocZ {
  width: 100%;
  height: 100%;
  font-family: Open Sans;
  font-size: 22px;
  line-height: 30px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.87);
}
._1j1wdPBOY7T1BI3btsG9TN ._36Xc6cE4IRqetWyJClSocZ::placeholder {
  color: rgba(32, 35, 37, 0.6);
}
._2_N78f1mxKLSLVWdAaLNPy {
  padding: 8px;
  background: #FFF4F4;
  box-shadow: inset 0px 1px 0px #EE2C4A;
  border-radius: 0px 0px 4px 4px;
  color: #EE2C4A;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
}
@media (max-device-width: 1108px) {
  ._3RazTYfrCNNK7gEIkvfqLh {
    margin-bottom: 16px;
  }
  ._2_N78f1mxKLSLVWdAaLNPy {
    font-size: 12px;
    line-height: 16px;
  }
}
