._3uhod8XYI6IeiWjHszu_Aw {
  padding: 12px 54px;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #FFFFFF;
  background-color: #EA5F00;
  border-radius: 20px;
  border: none;
  outline: none;
  cursor: pointer;
}
