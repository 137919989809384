.XH_eSweLqb66X_uCLNiS0 {
  display: flex;
  justify-content: flex-end;
}
.XH_eSweLqb66X_uCLNiS0 > div {
  flex: 1;
}
.XH_eSweLqb66X_uCLNiS0 ._3Gj3WeHUoJzIbYKMBh7lQx {
  padding: 12px;
  font-weight: 600;
  color: #000000;
  font-size: 22px;
  line-height: 32px;
}
.XH_eSweLqb66X_uCLNiS0 ._2o41m-x76PxehogL8lmp3m {
  width: 288px;
  color: #333a41;
  font-size: 16px;
  line-height: 28px;
}
.XH_eSweLqb66X_uCLNiS0 ._2o41m-x76PxehogL8lmp3m._2zuGEhP4WJXGyfSFaM_MGC {
  color: #ea5f00;
}
.XH_eSweLqb66X_uCLNiS0 ._2o41m-x76PxehogL8lmp3m ._21z9IQAxsEYB-Srd7d-QEt {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  cursor: pointer;
}
.XH_eSweLqb66X_uCLNiS0 ._2o41m-x76PxehogL8lmp3m ._2xoz8RN2k26QZuq8bj3sHW {
  padding-left: 12px;
}
.XH_eSweLqb66X_uCLNiS0 ._245-CjyAGv6SemUjX5Km-E {
  width: 12px;
  height: 12px;
}
@media (max-device-width: 1108px) {
  .XH_eSweLqb66X_uCLNiS0 {
    justify-content: flex-start;
  }
  .XH_eSweLqb66X_uCLNiS0 > div {
    width: 100%;
  }
  .XH_eSweLqb66X_uCLNiS0 ._3Gj3WeHUoJzIbYKMBh7lQx {
    padding-top: 20px;
    padding-bottom: 24px;
    text-align: center;
  }
  .XH_eSweLqb66X_uCLNiS0 ._2o41m-x76PxehogL8lmp3m {
    margin-left: 18px;
    margin-right: 18px;
    width: calc(100% - 36px);
    border-bottom: 1px solid #EEEFF2;
  }
  .XH_eSweLqb66X_uCLNiS0 ._2o41m-x76PxehogL8lmp3m ._21z9IQAxsEYB-Srd7d-QEt {
    padding: 20px 0;
  }
  .XH_eSweLqb66X_uCLNiS0 ._2o41m-x76PxehogL8lmp3m:last-child {
    border-bottom: none;
  }
  .XH_eSweLqb66X_uCLNiS0 ._2o41m-x76PxehogL8lmp3m._2zuGEhP4WJXGyfSFaM_MGC {
    color: #333a41;
  }
}
