.ynteyyj_4GKPcoSjaX-qP {
  padding-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
._2HrsWngDQC-qPxci-jmBb7 {
  font-family: Open Sans;
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  text-align: center;
  color: #000000;
}
.mmrIXmFVrWokXkM3TiwEn {
  margin-top: 48px;
}
._2pWHgQnlh8aEDqJzybQeCf {
  font-family: Open Sans;
  font-size: 16px;
  line-height: 28px;
  color: #797B7C;
  white-space: pre-wrap;
  margin: 0;
}
@media (max-device-width: 1108px) {
  .ynteyyj_4GKPcoSjaX-qP {
    padding: 40px 18px 0 18px;
    width: 100%;
  }
  ._2HrsWngDQC-qPxci-jmBb7 {
    font-size: 22px;
    line-height: 36px;
  }
  .mmrIXmFVrWokXkM3TiwEn {
    margin-top: 24px;
    width: auto;
  }
}
