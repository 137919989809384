._1-_8C-wbro4mHNAwaFuS5w {
  max-height: 480px;
  overflow-y: scroll;
}
.mwFEYU9H5J8tqHz0xsTm3 {
  position: relative;
}
.mwFEYU9H5J8tqHz0xsTm3 ._2wNBa96NtK4J7_VhAmkJrq {
  position: absolute;
  top: 56px;
  width: 100%;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
}
.mwFEYU9H5J8tqHz0xsTm3 ._2wNBa96NtK4J7_VhAmkJrq p {
  padding: 16px 0;
  padding-left: 56px;
  font-size: 16px;
  line-height: 22px;
  color: #202325;
  text-align: left;
}
.mwFEYU9H5J8tqHz0xsTm3 ._2wNBa96NtK4J7_VhAmkJrq p:hover {
  cursor: pointer;
  background-color: #f5f5f5;
}
.mwFEYU9H5J8tqHz0xsTm3 ._2wNBa96NtK4J7_VhAmkJrq p:nth-child(2n) {
  background-color: #f5f5f5;
}
.mwFEYU9H5J8tqHz0xsTm3 ._2wNBa96NtK4J7_VhAmkJrq em {
  font-style: normal;
  color: #ea5f00;
}
.mwFEYU9H5J8tqHz0xsTm3 ._2wNBa96NtK4J7_VhAmkJrq ._3rcDbIv9Q-rciZa4rtb49W {
  height: 72px;
  line-height: 72px;
  color: rgba(0, 0, 0, 0.54);
}
.JLKy-9MaaLkZQt6bnBQZ7 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 60px;
  padding-bottom: 52px;
  color: rgba(0, 0, 0, 0.54);
}
.JLKy-9MaaLkZQt6bnBQZ7 img {
  width: 160px;
  height: 160px;
}
@media (max-device-width: 1108px) {
  .mwFEYU9H5J8tqHz0xsTm3 {
    margin: 0 12px;
  }
}
