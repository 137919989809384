._1NFu_UQgLJndw5ZOVVmsS7 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
._1NFu_UQgLJndw5ZOVVmsS7 ._2_L4xynJMt9pO6kEAGBPW5 {
  margin: 80px auto 48px;
}
._1NFu_UQgLJndw5ZOVVmsS7 ._3GZZirZ1riU19ForJuyj7X {
  text-align: center;
  margin-bottom: 80px;
}
._1NFu_UQgLJndw5ZOVVmsS7 ._1L-mv1YaQ98de12QCfeHmh {
  margin-bottom: 100px;
}
._1NFu_UQgLJndw5ZOVVmsS7 ._2TV1LBR0LtJsFg21r99ws {
  margin-bottom: 0;
}
@media (max-device-width: 1108px) {
  ._1NFu_UQgLJndw5ZOVVmsS7 ._2_L4xynJMt9pO6kEAGBPW5 {
    width: 150px;
    height: 48px;
    margin: 60px auto 36px;
  }
  ._1NFu_UQgLJndw5ZOVVmsS7 ._3GZZirZ1riU19ForJuyj7X {
    text-align: left;
    margin: 0 18px 48px;
  }
}
