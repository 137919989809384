._1-JYuPQXH96RFbfki_d9Br {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 120px;
  padding-bottom: 200px;
}
._3Jrwb2QDxKzcPYlU7yApZj {
  font-size: 120px;
  font-weight: 600;
  line-height: 1;
  color: #ea5f00;
  text-align: center;
}
._1ydv7t_Sf6wxKuY4gQ2JUr {
  margin-top: 68px;
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  color: #202325;
  text-align: center;
}
._3Uh-3-KcEnrC8COLwatwds {
  width: 480px;
  color: #202325;
  opacity: 0.6;
  margin-top: 24px;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  font-weight: 400;
}
._3QnFQECzEzUfRvKg3AsVKa {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
footer > div {
  margin-top: 68px;
}
._3BS-E7B2RDZlHrrQM91nVn {
  width: 180px;
  height: 40px;
  font-size: 16px;
  line-height: 40px;
  font-weight: 600;
  color: #ffffff;
  border-radius: 20px;
  background-color: #ea5f00;
  cursor: pointer;
  text-align: center;
}
._1_TheHTkzAqTPFQwT309sp {
  display: flex;
  align-items: center;
  margin-top: 40px;
}
._1_TheHTkzAqTPFQwT309sp > a {
  cursor: pointer;
  margin-right: 14px;
  width: 150px;
  height: 47px;
}
@media (max-device-width: 1108px) {
  ._1-JYuPQXH96RFbfki_d9Br {
    padding: 68px 49px 80px;
  }
  ._1ydv7t_Sf6wxKuY4gQ2JUr {
    font-size: 22px;
    line-height: 1.45;
    margin-top: 16px;
  }
  ._3Uh-3-KcEnrC8COLwatwds {
    width: auto;
    margin-top: 16px;
  }
  ._3QnFQECzEzUfRvKg3AsVKa > div {
    margin-top: 72px;
  }
}
._1_TheHTkzAqTPFQwT309sp {
  display: flex;
  align-items: center;
  margin-top: 36px;
}
._1_TheHTkzAqTPFQwT309sp > a {
  cursor: pointer;
  margin-right: 14px;
  width: 150px;
  height: 47px;
}
