._3OadvOeQt1qzJJZyTAbIBB {
  margin: 80px auto 82px auto;
  text-align: center;
  font-family: Open Sans;
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  color: #000000;
}
._1vcOQSTLGA7qv8FN-J4FMO {
  margin-bottom: 140px;
}
._31fnvPLDbI0MWPS5Fi2pGs {
  width: 100%;
  height: 460px;
  background-color: #fafafa;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.06);
}
._3LVvV1GwzyUhW0O0FJFzSo {
  display: flex;
  justify-content: center;
  height: 100%;
}
._6LYyfeRRXNnHy8NT11lNb {
  display: flex;
}
._3gIYVyycqQ096y-WHixBuB {
  position: relative;
  width: 420px;
}
._3gIYVyycqQ096y-WHixBuB > img {
  position: absolute;
  top: -82px;
  left: 0;
  width: 420px;
  height: 680px;
}
._2BflZHBpke_9kuiGTNoNuH {
  width: 120px;
  height: 100%;
}
._1xBaUcChohgCSvR7-F81dK {
  width: 396px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: Open Sans;
}
._1xBaUcChohgCSvR7-F81dK ._3uwCvXy-ghfE2q67Nq14d7 {
  font-weight: 600;
  font-size: 22px;
  line-height: 32px;
  color: #333a41;
}
._1xBaUcChohgCSvR7-F81dK ._3YXjbN5D0XOmbMbTDMJ1IG {
  margin-top: 12px;
  font-size: 16px;
  line-height: 28px;
  color: #797B7C;
}
._1xBaUcChohgCSvR7-F81dK ._1DL_0w_fCKvowfPhy-N55I {
  margin-top: 36px;
  height: 48px;
  width: 227px;
  line-height: 48px;
  text-align: center;
  background: #ea5f00;
  border-radius: 28px;
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
  cursor: pointer;
}
.Pq3f2T3Sj6k3Mt9I0zpFN {
  padding-top: 110px;
  padding-bottom: 72px;
  background-color: #fafafa;
  display: flex;
  justify-content: center;
}
.Pq3f2T3Sj6k3Mt9I0zpFN ._2a2T2KuOvEInzUIXRD2qLU {
  display: flex;
  justify-content: center;
}
@media (max-device-width: 1108px) {
  ._3OadvOeQt1qzJJZyTAbIBB {
    margin: 80px auto 39px auto;
    font-size: 22px;
    line-height: 32px;
  }
  ._1vcOQSTLGA7qv8FN-J4FMO {
    margin-bottom: 0px;
  }
  ._1vcOQSTLGA7qv8FN-J4FMO:last-child {
    margin-bottom: 48px;
  }
  ._31fnvPLDbI0MWPS5Fi2pGs {
    height: auto;
    box-shadow: none;
  }
  ._3LVvV1GwzyUhW0O0FJFzSo {
    height: auto;
  }
  ._6LYyfeRRXNnHy8NT11lNb {
    display: block;
  }
  ._3gIYVyycqQ096y-WHixBuB {
    width: 100%;
  }
  ._3gIYVyycqQ096y-WHixBuB > img {
    position: relative;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 296px;
    height: 480px;
  }
  ._2BflZHBpke_9kuiGTNoNuH {
    display: none;
  }
  ._1xBaUcChohgCSvR7-F81dK {
    width: 100%;
    padding: 0 18px 37px 18px;
    display: block;
    position: relative;
  }
  ._1xBaUcChohgCSvR7-F81dK ._1DL_0w_fCKvowfPhy-N55I {
    position: relative;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    cursor: default;
  }
  .Pq3f2T3Sj6k3Mt9I0zpFN {
    padding-top: 0;
    padding-bottom: 36px;
  }
  .Pq3f2T3Sj6k3Mt9I0zpFN ._2a2T2KuOvEInzUIXRD2qLU {
    margin: 0 12px;
  }
}
