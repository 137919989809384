._14eakWbkGhAFvfyVaFMQg5 {
  display: flex;
  justify-content: center;
  background-color: #2673CD;
}
._2fojHN_kxQ8VMINy_jgP1o {
  text-align: center;
  font-family: Open Sans;
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  color: #000000;
  padding-top: 80px;
  padding-bottom: 24px;
}
._3HL4AYAL5ArlMsxCt1cZl6 {
  width: 100%;
  height: 480px;
}
.ig4Z7zLnUs7rJCz4Z81gK {
  padding-top: 148px;
}
._1TANK9TPs1k0c9PjlpMp6N {
  max-width: 785px;
  font-family: 'Shopee 2021';
  font-size: 48px;
  line-height: 60px;
  color: #FFFFFF;
}
._2F3aJ-_7oi4EknJHhn6pkD {
  font-family: Open Sans;
  font-size: 16px;
  line-height: 28px;
  color: #FFFFFF;
  margin-top: 35px;
}
@media (max-device-width: 1108px) {
  ._2fojHN_kxQ8VMINy_jgP1o {
    font-size: 22px;
    line-height: 36px;
    padding-top: 40px;
    padding-bottom: 24px;
  }
  ._3HL4AYAL5ArlMsxCt1cZl6 {
    width: 100%;
    height: 320px;
  }
  .ig4Z7zLnUs7rJCz4Z81gK {
    padding: 194px 21px 0 21px;
  }
  ._1TANK9TPs1k0c9PjlpMp6N {
    max-width: none;
    font-size: 22px;
    line-height: 22px;
    text-align: center;
  }
  ._2F3aJ-_7oi4EknJHhn6pkD {
    margin-top: 16px;
    text-align: center;
  }
}
