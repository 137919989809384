._3-WuA0sQTCkrtykZdXy8gy {
  background-color: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}
._2rgxloXB93YyLZRZztRn9i {
  height: 64px;
}
._1uLZ1isw5v81HRWDmOutaj {
  display: none;
}
._3a44EctTtK3XykKoR1t79L {
  display: flex;
  align-items: center;
  height: 64px;
  font-size: 14px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.87);
  margin: 0;
  white-space: nowrap;
  border: 0;
  box-shadow: none;
}
._3a44EctTtK3XykKoR1t79L > li {
  height: 100%;
  position: relative;
  display: inline-block;
  margin: 0 24px;
  text-decoration: none;
  cursor: pointer;
}
._3a44EctTtK3XykKoR1t79L > li:last-child {
  margin-right: 0;
}
._3a44EctTtK3XykKoR1t79L > li > div {
  height: 100%;
  display: flex;
  align-items: center;
}
._3a44EctTtK3XykKoR1t79L > li > div span {
  padding: 0 3px;
  text-align: center;
}
._3a44EctTtK3XykKoR1t79L > li ._1ii-6sdNQ0UTmLang7yAtL {
  display: none;
}
._3a44EctTtK3XykKoR1t79L ._3nEqdjFAelG2iUgNFxnTiR:hover > span {
  color: #EA5F00;
}
._3a44EctTtK3XykKoR1t79L ._2c9ltGvcKx7q9hOiBdXjYQ > span {
  color: #EA5F00;
}
.NqpkRyDxRH5yRrAg6xmUY {
  display: flex;
  align-items: center;
}
._2-2N3qFYkJoWruVLw2JfKy {
  height: 40px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  color: #ffffff;
  border-radius: 20px;
  background-color: #ea5f00;
  padding: 8px 22px;
  margin-left: 48px;
  cursor: pointer;
}
._1R-LJ1nVu05eF76aAgNhTS {
  display: none;
}
.gQh2FzP1jDRwJCD8GtCIX {
  display: none;
}
@media (max-device-width: 1108px) {
  ._3-WuA0sQTCkrtykZdXy8gy {
    box-shadow: none;
    border-bottom: 1px solid #eeeeee;
  }
  ._2rgxloXB93YyLZRZztRn9i {
    height: 48px;
    padding: 0 12px 0 18px;
  }
  ._2rgxloXB93YyLZRZztRn9i .logo {
    width: 88px;
    height: 28px;
  }
  ._1uLZ1isw5v81HRWDmOutaj {
    display: flex;
    align-items: center;
  }
  ._3cPDamG18B8z-ABJfLu4yy {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .NqpkRyDxRH5yRrAg6xmUY {
    display: none;
  }
  ._1R-LJ1nVu05eF76aAgNhTS {
    display: block;
    font-weight: 600;
    font-size: 13px;
    line-height: 24px;
    height: 32px;
    color: #ffffff;
    border-radius: 20px;
    background-color: #ea5f00;
    padding: 4px 12px;
    margin-right: 12px;
  }
  .gQh2FzP1jDRwJCD8GtCIX {
    display: block;
    visibility: hidden;
    position: absolute;
    z-index: 10;
    right: 0;
    left: 0;
    height: 100vh;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  .gQh2FzP1jDRwJCD8GtCIX ._3a44EctTtK3XykKoR1t79L {
    display: block;
    width: 229px;
    height: 100%;
    background-color: #ffffff;
    border-top: 1px solid #eeeeee;
    float: right;
    padding: 14px 12px;
    transition: all 0.3s ease;
    transform: translateX(220px);
  }
  .gQh2FzP1jDRwJCD8GtCIX ._3a44EctTtK3XykKoR1t79L > li {
    height: auto;
    display: block;
    margin: 0;
    cursor: default;
  }
  .gQh2FzP1jDRwJCD8GtCIX ._3a44EctTtK3XykKoR1t79L > li ._1ii-6sdNQ0UTmLang7yAtL {
    display: block;
    width: 100%;
    height: 1px;
    margin: 9px 0;
    background: #EEEEEE;
  }
  .gQh2FzP1jDRwJCD8GtCIX ._3a44EctTtK3XykKoR1t79L > li > div {
    display: block;
  }
  .gQh2FzP1jDRwJCD8GtCIX ._3a44EctTtK3XykKoR1t79L > li span {
    text-align: left;
    display: block;
    padding: 11px 12px;
  }
  .gQh2FzP1jDRwJCD8GtCIX ._3a44EctTtK3XykKoR1t79L ._2c9ltGvcKx7q9hOiBdXjYQ span {
    background: #fff4f4;
    border-radius: 6px;
  }
  ._1D3WGwqesxyrRQlkHN9Rw3 {
    visibility: visible;
    opacity: 1;
  }
  ._1D3WGwqesxyrRQlkHN9Rw3 ._3a44EctTtK3XykKoR1t79L {
    transform: translateX(0);
  }
  ._3z6MmHPcO-i7XVjuE01P3G li {
    list-style: none;
  }
  ._3z6MmHPcO-i7XVjuE01P3G ._2ieoR66kcq_xOoMk9U-veS {
    color: rgba(117, 117, 117, 0.5);
  }
}
