.iVebZIPQsVLCrvpAfidFd {
  border-top: 1px solid rgba(0, 0, 0, 0.09);
}
._3XovrCZfOcXCAoSb8-Sny {
  padding: 30px 0;
  font-weight: 600;
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  font-size: 24px;
  line-height: 24px;
}
._1E_-kCqkE15qHh9q1C3B4w > div:first-child {
  margin: 24px 0;
}
@media (max-device-width: 1108px) {
  ._3XovrCZfOcXCAoSb8-Sny {
    padding: 12px 0;
    text-align: center;
    border-bottom: none;
    font-size: 22px;
    line-height: 28px;
  }
  ._1E_-kCqkE15qHh9q1C3B4w > div:first-child {
    margin: 12px 0;
  }
}
