._3TlevG_h5T6GbPuYOY9oLH {
  display: flex;
  justify-content: center;
  background-color: #ffffff;
}
.MXKt_a61KAdItbIwK5Gys {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 60px;
}
._3GiObIJkewChtBpazXH5g2 {
  max-width: 642px;
}
._1A3dg8AkyK1CYz7EKMFVbW {
  font-size: 16px;
  line-height: 24px;
  color: rgba(32, 35, 37, 0.6);
}
._1GxQLckKWQkBVTUNfTt3Q7 {
  position: relative;
  margin-top: -352px;
  width: 420px;
  height: 680px;
}
@media (max-device-width: 1108px) {
  ._1GxQLckKWQkBVTUNfTt3Q7 {
    margin-top: 0;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 296px;
    height: 480px;
  }
  .MXKt_a61KAdItbIwK5Gys {
    margin: 48px 24px;
  }
  ._1A3dg8AkyK1CYz7EKMFVbW {
    font-size: 16px;
    line-height: 28px;
  }
}
