._3012OkiL34ciQ2gqb4_G_b {
  position: relative;
  display: flex;
  justify-content: center;
}
._2qUlIHHwvHBhmoldVy2uoC {
  position: absolute;
  left: 0;
  top: 36px;
  width: 100%;
  height: 460px;
  background-color: #fafafa;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.06);
}
._3T37SBxtE-3blp9aW-S8VQ {
  text-align: center;
  font-family: Open Sans;
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  color: #000000;
  padding-top: 80px;
  padding-bottom: 48px;
}
._1izgKEdLhVIVHcmCujrZvh {
  display: flex;
  justify-content: center;
}
._3fc-ZkrBD4k3Ooo2MD3oct {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 1260px;
  height: 460px;
  margin-top: 36px;
  margin-bottom: 49px;
}
._1hT5SkwvLsX0qLb5TFfWLw {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 102px;
  padding-bottom: 83px;
  width: 400px;
  height: 100%;
}
._2Ry9pjOmrpdT-6ej_yEcQt {
  position: relative;
  width: 760px;
  height: 460px;
}
.FeWz_xqhicrdK6gMWkSTM {
  position: absolute;
  top: 0;
  left: 0;
  width: 760px;
  height: 460px;
}
.FeWz_xqhicrdK6gMWkSTM > img {
  position: absolute;
  left: 0;
  top: 0;
  width: 760px;
  height: 460px;
  opacity: 0;
  transition: opacity 0.7s ease;
}
.FeWz_xqhicrdK6gMWkSTM ._3Qb8sRfOLf7PTJ9Kb13ynt {
  opacity: 1;
}
.GE8on-IPk1zmpC86v-dh- {
  position: relative;
}
.GE8on-IPk1zmpC86v-dh- ._2afne1UQV5Ur66A6OWbf7n {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  transition: opacity 0.7s ease;
}
.GE8on-IPk1zmpC86v-dh- ._18OFo6d9Znhep6KBIJ_WJ8 {
  opacity: 1;
}
._2G-J0B8Y-Fk8RewMz67N0u {
  font-family: Open Sans;
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  color: #333a41;
  padding-bottom: 16px;
}
._1kfs__lIkVm4igCqRh84O7 {
  font-family: 'Shopee 2021';
  font-weight: 900;
  font-size: 32px;
  line-height: 48px;
  color: #EA5F00;
  padding-bottom: 8px;
}
._2-YbHU0NbBRJdr3KRTE64V {
  font-family: Open Sans;
  font-weight: 600;
  font-size: 22px;
  line-height: 32px;
  color: #333a41;
}
.km7a8-PTraAsamII3PQgm {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-left: -10px;
}
._3I-er1oH0F9eHCiWu7FvF3 {
  width: auto;
  height: 100%;
}
.nuKLtwo3rZYrbl3Gzffva {
  position: relative;
  align-self: flex-start;
  top: -36px;
  width: 284px;
  height: 545px;
  margin: 0 40px;
  border-radius: 24px;
  overflow: hidden;
  background-color: #ffffff;
  box-shadow: 0px 24px 68px rgba(0, 0, 0, 0.16);
  box-sizing: border-box;
  border: 1px solid #C4C4C4;
}
.nuKLtwo3rZYrbl3Gzffva > img {
  position: absolute;
  top: 36px;
  left: 50%;
  transform: translateX(-50%);
  width: 260px;
  height: 460px;
  opacity: 0;
  transition: opacity 0.7s ease;
}
.nuKLtwo3rZYrbl3Gzffva ._2iF5CZvk2R_uWmMbaRmei0 {
  opacity: 1;
}
._3bFWnzgz1XJlm7cEKUr8HL {
  position: relative;
  width: 104px;
  height: 8px;
}
._3bFWnzgz1XJlm7cEKUr8HL > div {
  display: inline-block;
  margin-right: 8px;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.26);
  transition: all 0.7s ease;
}
._3bFWnzgz1XJlm7cEKUr8HL > div:last-child {
  margin-right: 0;
}
._3bFWnzgz1XJlm7cEKUr8HL ._8-TQDOdSHSUNhxdZVnEF1 {
  width: 24px;
  background-color: #EA5F00;
}
.SThDZVzrRH4PSrgY0aeL8 {
  filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.1));
  width: 60px;
  height: 60px;
  cursor: pointer;
}
@media (max-width: 1420px) {
  ._1hT5SkwvLsX0qLb5TFfWLw {
    width: 360px;
  }
}
@media (max-device-width: 1108px) {
  ._3T37SBxtE-3blp9aW-S8VQ {
    font-size: 22px;
    line-height: 36px;
    padding-top: 40px;
    padding-bottom: 24px;
  }
  ._2qUlIHHwvHBhmoldVy2uoC {
    top: 0;
    height: 100%;
  }
  ._3fc-ZkrBD4k3Ooo2MD3oct {
    height: 612px;
    padding: 48px 0 0 0;
    margin: 0;
    flex-direction: column-reverse;
    justify-content: flex-end;
  }
  ._1hT5SkwvLsX0qLb5TFfWLw {
    max-width: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0;
    width: 100%;
  }
  ._2Ry9pjOmrpdT-6ej_yEcQt {
    width: 100%;
    height: 385px;
  }
  .GE8on-IPk1zmpC86v-dh- ._2afne1UQV5Ur66A6OWbf7n {
    width: 100%;
    padding: 40px 38px 0 38px;
  }
  ._2G-J0B8Y-Fk8RewMz67N0u {
    font-size: 22px;
    line-height: 32px;
    padding-bottom: 8px;
  }
  ._1kfs__lIkVm4igCqRh84O7 {
    font-weight: 900;
    font-size: 22px;
    line-height: 32px;
    padding-bottom: 0;
  }
  ._2-YbHU0NbBRJdr3KRTE64V {
    font-size: 16px;
    line-height: 28px;
  }
  .km7a8-PTraAsamII3PQgm {
    justify-content: space-between;
    margin: 0;
  }
  ._3I-er1oH0F9eHCiWu7FvF3 {
    display: flex;
    justify-content: center;
    position: absolute;
    z-index: 0;
    top: 0;
    height: 385px;
    width: 100%;
  }
  .nuKLtwo3rZYrbl3Gzffva {
    position: absolute;
    top: 0;
    margin: 0 61px;
    width: 200px;
    height: 385px;
    margin: 0;
    border-radius: 16px;
    box-shadow: 0px 16px 48px rgba(0, 0, 0, 0.16);
  }
  .nuKLtwo3rZYrbl3Gzffva > img {
    top: 25px;
    width: 182px;
    height: 325px;
  }
  .SThDZVzrRH4PSrgY0aeL8 {
    position: relative;
    z-index: 2;
    margin: 0 9px;
  }
  .SThDZVzrRH4PSrgY0aeL8:last-child {
    margin-left: 0;
  }
  .FeWz_xqhicrdK6gMWkSTM {
    display: none;
  }
  ._3bFWnzgz1XJlm7cEKUr8HL {
    display: none;
  }
}
