._3lB1nDH4uj3zgIXHXPIEm3 {
  background-color: red;
}
._2mr8V8C7KoqKXBD3_aiGxw {
  padding: 40px;
  background: #ffffff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  position: relative;
}
._2s25z8JdbNkUGGcfyuq_7C {
  position: absolute;
  top: -74px;
}
._1gOHJiiWnnNjc7sXOpsIwC {
  position: absolute;
  top: -55px;
}
.-lOwnapsh-ab0uFI_vLSl {
  text-align: center;
  margin-bottom: 40px;
}
.-lOwnapsh-ab0uFI_vLSl h3 {
  font-weight: 600;
  font-size: 22px;
  line-height: 32px;
  line-height: 30px;
}
.-lOwnapsh-ab0uFI_vLSl ._1mM5IxDs_lZC_ukgjbFxyl {
  display: flex;
  justify-content: center;
  align-items: center;
}
.-lOwnapsh-ab0uFI_vLSl ._3UlI3Pd4vTDewaZ_5752nF {
  color: #ee4d2d;
}
.-lOwnapsh-ab0uFI_vLSl ._4bU97pJ-q9h7QVd7UVu_3 {
  height: 16px;
  width: 16px;
  margin-left: 9px;
}
._1oLnARhUZ-mY3TDx-ircBh {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 12px;
}
._3AGzBDgW2wMV6mzuMeQEAi ._1y7nr6BCOM3U81oKfhb7eS {
  font-size: 16px;
  line-height: 28px;
  font-weight: 400px;
  margin-bottom: 32px;
  color: #797b7c;
}
._3AGzBDgW2wMV6mzuMeQEAi ._1y7nr6BCOM3U81oKfhb7eS a {
  color: #2673dd;
}
._3AGzBDgW2wMV6mzuMeQEAi ._2xQl-lhNs4BLe7EVZQzfc7 ._2yKesIXV5Yag8tL6HmcBCG {
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(51, 64, 79, 0.09);
  border-radius: 8px;
  padding: 26px 20px;
  margin-bottom: 32px;
  font-family: Open Sans;
  font-size: 22px;
  line-height: 30px;
  font-weight: 600;
}
._3AGzBDgW2wMV6mzuMeQEAi ._2xQl-lhNs4BLe7EVZQzfc7 ._2yKesIXV5Yag8tL6HmcBCG:last-child {
  margin-bottom: 0;
}
._3AGzBDgW2wMV6mzuMeQEAi ._2xQl-lhNs4BLe7EVZQzfc7 ._2yKesIXV5Yag8tL6HmcBCG ._19U21thqiPnnXJ0UOyA0Sj {
  color: #26AA43;
}
._3AGzBDgW2wMV6mzuMeQEAi ._2xQl-lhNs4BLe7EVZQzfc7 ._2yKesIXV5Yag8tL6HmcBCG ._21p0wtxNSdWpzzosfYe1aJ {
  color: rgba(0, 0, 0, 0.54);
}
._1yn-G8xB-Lg7TZAZPhtMsW {
  width: 32px;
  height: 32px;
}
@media (max-device-width: 1108px) {
  ._2mr8V8C7KoqKXBD3_aiGxw {
    padding: 24px 20px;
  }
  .-lOwnapsh-ab0uFI_vLSl {
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
  .-lOwnapsh-ab0uFI_vLSl h3 {
    font-size: 18px;
    line-height: 32px;
  }
  ._1oLnARhUZ-mY3TDx-ircBh {
    font-size: 16px;
    line-height: 22px;
  }
  ._3AGzBDgW2wMV6mzuMeQEAi ._1y7nr6BCOM3U81oKfhb7eS {
    margin-bottom: 20px;
  }
  ._3AGzBDgW2wMV6mzuMeQEAi ._2xQl-lhNs4BLe7EVZQzfc7 ._2yKesIXV5Yag8tL6HmcBCG {
    padding: 20px;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 16px;
  }
}
