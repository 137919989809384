.hvicMSx2vF9DSwykucCJv {
  width: 100%;
  height: 100%;
  padding: 16px;
}
.hvicMSx2vF9DSwykucCJv ._2LFRiMpeOrSoRBf7EpdOZJ {
  font-size: 22px;
  line-height: 32px;
  font-weight: 600;
  color: #333a41;
  margin-bottom: 8px;
}
.hvicMSx2vF9DSwykucCJv ._3irvMTyeLblGqLh51hZZ0z {
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: #333a41;
}
._1uwjoJsicZ6e_Y5PjbPdMx {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-flow: row wrap;
  max-width: calc(100% + 36px);
}
._1uwjoJsicZ6e_Y5PjbPdMx ._FlqVictpJCuI4kqJSlnw {
  margin-top: 12px;
  margin-bottom: 12px;
  background-color: #f8f8f8;
}
@media (max-device-width: 1108px) {
  ._1uwjoJsicZ6e_Y5PjbPdMx {
    padding: 0 18px;
  }
}
