._1AfRf2AKyHNsMDuYXmyZOA {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 80px;
}
._19m5bFiZvtBQ4HPOp9ztOq {
  width: 100%;
  text-align: center;
  margin-bottom: 48px;
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
}
._3qDDCNLOCvlhjxhe1QOKOf {
  display: flex;
  flex-direction: column;
  align-items: center;
}
._1xmBf1gr-gsJZ8TM8GQnyH {
  width: 100%;
  overflow: hidden;
}
._2UbzcYUTCcdMp0OLWP_r_O {
  margin: 24px 0 16px;
}
._2gn21aHGJ3S9H5TZJifpwJ {
  width: 416px;
  text-align: center;
}
@media (max-device-width: 1108px) {
  ._1AfRf2AKyHNsMDuYXmyZOA {
    margin-bottom: 60px;
  }
  ._19m5bFiZvtBQ4HPOp9ztOq {
    padding: 0 18px;
    font-weight: 600;
    font-size: 22px;
    line-height: 32px;
  }
  ._3qDDCNLOCvlhjxhe1QOKOf:first-child {
    margin-bottom: 36px;
  }
  ._2UbzcYUTCcdMp0OLWP_r_O {
    margin: 12px 0;
  }
  ._2gn21aHGJ3S9H5TZJifpwJ {
    width: 100%;
    padding: 0 18px;
  }
}
