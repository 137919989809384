._1sk953NUn7JoUQL2UNS4qg {
  display: flex;
  justify-content: center;
  padding-top: 80px;
  padding-bottom: 200px;
}
._1sk953NUn7JoUQL2UNS4qg._3GAqwlCVAwaWU-2-XthGQv {
  padding-top: 0;
  padding-bottom: 0;
}
._1sk953NUn7JoUQL2UNS4qg._3GAqwlCVAwaWU-2-XthGQv .ZYsMifb_qHPuKzZARIKuj ._3HVNfoefI67T8ClwlB7bYV {
  line-height: 16px;
  margin-top: 12px;
  margin-bottom: 0;
}
._1sk953NUn7JoUQL2UNS4qg .ZYsMifb_qHPuKzZARIKuj {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 480px;
}
._1sk953NUn7JoUQL2UNS4qg .ZYsMifb_qHPuKzZARIKuj ._3TYyJ5vC8TOOZvahx1ckcn {
  font-weight: 600;
  font-size: 32px;
  line-height: 46px;
}
._1sk953NUn7JoUQL2UNS4qg .ZYsMifb_qHPuKzZARIKuj ._3HVNfoefI67T8ClwlB7bYV {
  color: rgba(0, 0, 0, 0.54);
  margin-top: 26px;
  margin-bottom: 68px;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  color: rgba(32, 35, 37, 0.6);
}
@media (max-device-width: 1108px) {
  ._1sk953NUn7JoUQL2UNS4qg {
    padding-top: 36px;
    padding-bottom: 80px;
  }
  ._1sk953NUn7JoUQL2UNS4qg .ZYsMifb_qHPuKzZARIKuj {
    max-width: 270px;
  }
  ._1sk953NUn7JoUQL2UNS4qg .ZYsMifb_qHPuKzZARIKuj ._3TYyJ5vC8TOOZvahx1ckcn {
    font-size: 22px;
    line-height: 32px;
  }
}
.rfqzXuIg6-Uc0GXpE2Ozv {
  width: 80px;
  height: 80px;
}
