._18qGNgGvQ47WrqR6kY2hmt {
  width: 100%;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F9F9F9;
}
._1K0kqK1rIY5VIQyDBVU0I2 {
  width: auto;
  height: 58px;
  position: relative;
  padding-bottom: 4px;
  margin-right: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
._1K0kqK1rIY5VIQyDBVU0I2:last-child {
  margin-right: 0;
}
._1K0kqK1rIY5VIQyDBVU0I2:hover .TMd6GM2X-4rIyRQjRE-_L {
  color: #EA5F00;
}
.TMd6GM2X-4rIyRQjRE-_L {
  font-size: 14px;
  line-height: 24px;
  color: #797B7C;
}
._26z6QEzUUnGFS0f3yVxcuf {
  font-size: 14px;
  line-height: 24px;
  color: #797B7C;
  color: #EA5F00;
}
._2CUXH_mRbc7TW1LZd_mxEV {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 4px;
  border-radius: 2px;
  background: #EA5F00;
}
