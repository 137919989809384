._2M8_A0FpxMTgC814-TXXkX {
  font-size: 36px;
  color: #333a41;
  width: 100%;
  text-align: center;
  margin-bottom: 60px;
  font-weight: bold;
}
.A1ME6yBobCoj4MPu7ZNBe {
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  margin-bottom: 16px;
  text-align: center;
}
.s3hCvBe3xbiNqh22RJ2X_ {
  margin-bottom: 48px;
  text-align: center;
}
._1UyWjgZ18MGLdeGfth2zxa {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 16px 0 40px;
  background-color: #fafafa;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.06);
}
.eJgm8TgR_-hwGpYUgjwuO {
  width: 200px;
  height: 156px;
}
._3NTzkbidcBcRLHO8HV_GGS {
  width: 240px;
  max-width: 100%;
  padding: 0 8px;
}
._3NTzkbidcBcRLHO8HV_GGS ._2oIlO6M8HQnNqhb0BnQi8k {
  font-size: 16px;
  line-height: 28px;
  font-weight: 600;
  color: #202325;
}
._3NTzkbidcBcRLHO8HV_GGS ._2IKQP9gBRYKu7eNAc9JdCG {
  margin-top: 8px;
  font-size: 14px;
  line-height: 24px;
  color: #797b7c;
}
.T-gCXIHuCW5UIkEvAlRbE {
  max-width: 632px;
  margin: 36px auto 0;
  text-align: center;
  font-size: 14px;
  line-height: 24px;
  color: #797b7c;
}
._3XNVgfYuE8w-GNcug44qOz {
  margin: 36px auto 80px;
  color: #ffffff;
  background-color: #ea5f00;
  text-align: center;
  width: 300px;
  line-height: 56px;
  border-radius: 28px;
  cursor: pointer;
}
@media (max-device-width: 1108px) {
  .A1ME6yBobCoj4MPu7ZNBe {
    font-weight: 600;
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 24px;
  }
  .s3hCvBe3xbiNqh22RJ2X_ {
    margin: 0 18px;
  }
  ._1UyWjgZ18MGLdeGfth2zxa {
    background-color: #ffffff;
    box-shadow: none;
    padding: 48px 0 0;
  }
  ._1UyWjgZ18MGLdeGfth2zxa:first-child {
    padding: 0;
  }
  .T-gCXIHuCW5UIkEvAlRbE {
    margin-top: 24px;
  }
  ._3XNVgfYuE8w-GNcug44qOz {
    cursor: default;
  }
}
